/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import { toJS } from 'mobx';
import React, {
  useEffect,
  lazy,
  Suspense,
  useState,
  useLayoutEffect
} from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { withAuth } from '../components/authentication';
import ProtectedRoute from './ProtectedRoute';
import BuildInfo from './build-info/BuildInfo';
import PageTourWrapper from './page-tour-wrapper/PageTourWrapper';
import AlertMessage from './alert-message/AlertMessage';
import MessageDialog from './message-dialog/MessageDialog';
import Interceptor from '../interceptor/axiosInterceptor';
import NewReleaseDialog from '../components/new-release-dialog/NewReleaseDialog';
import NotificationDrawer from './notification-drawer/NotificationDrawer';
import ForbiddenPage from '../components/forbidden-page/ForbiddenPage';
import ServiceDown from '../components/service-down/ServiceDown';
import { checkPartnerHealth } from '../services/healthService';
import { getUserPreference } from '../services/preferences.service';
import Loader from './Loader';
import AppLoader from './Loader/AppLoader';
import { loadCookie } from '../utils';
import constants from '../constants';
import './app.style.scss';
import { gaInitializeGTM } from '../../ga';

// dynamic import
const HomePage = lazy(() => import('./home-page/HomePage'));
const ProjectDetailPage = lazy(
  () => import('../components/project-detail-page/ProjectDetailPage')
);
const PreflightCheckPage = lazy(() => import('./Preflight/PreflightCheckPage'));
const DistributionPage = lazy(() => import('./distribution/DistributionPage'));
const AlfrescoViewer = lazy(
  () => import('./AlfrescoViewer/AlfrescoViewerPage')
);
const PaceAIOverview = lazy(() => import('./ai-projects/AIProjectOverview'));
const ImportAssets = lazy(() => import('./importAssets/ImportAlfrescoAsset'));
const LessonBuilder = lazy(() => import('./lessonBuilder/LessonBuilder'));

// calling Interceptor for handling response code
Interceptor();

const App = props => {
  const {
    store: {
      UserInfo: { getUserInfo, userId },
      Config: { getConfig, RELEASE_NUMBER },
      Config,
      Filters: {
        setSortCriteria,
        toggleViewMode,
        setProjectPublishStatus,
        setProjectPublishPlatform,
        setShowNewReleaseContent
      },
      PublishPlatformOptions: { updateColumnVisibilityByUserPreference },
      SpecialPermissions: { getPermissions },
      ProjectDetail,
      FileUrls: { loadFileUrls },
      FeatureManager: { getFeatureList },
      UserPreference,
      UserPreferenceMasterData: {
        getMasterData: getUserPreferenceMasterData,
        tableModeColumns: tableModeColumnsMasterData
      },
      UserPreferenceMasterData,
      TableModeSettings: { updateColumnOrder },
      LobMetaMaster: { getLobMetaMaster },
      LobMeta: { getLobMeta },
      Loader: { disableLoader }
    }
  } = props;

  // if health service returns 503 or 504 then this state's status is true and user will be
  // redirected to Service Down page
  const [isServiceDown, setIsServiceDown] = useState({
    status: false,
    info: ''
  });

  // Load the user based release modal pop up view preference
  const userReleaseInfo = loadCookie(
    `${constants.RELEASE_CONTENT_FOR}${userId}`
  );

  if (userReleaseInfo) {
    const { showNewReleaseContent } = userReleaseInfo;
    setShowNewReleaseContent(showNewReleaseContent);
  }

  useEffect(() => {
    const fetchInitialData = async () => {
      if (!window.location.href.includes('#/forbidden')) {
        let isValidUser = false;
        /* if user is directly landing to forbidden page do not call any UDB API's-
      as this would indicate other partner team using our common forbidden page */

        const { status: userInfoCallStatus } = await getUserInfo();
        if (userInfoCallStatus !== 403) {
          isValidUser = true;
        }
        const { setUserPreference } = UserPreference;
        const response = await Promise.all([
          getConfig(),
          loadFileUrls(),
          isValidUser && getFeatureList(),
          isValidUser && getUserPreferenceMasterData(),
          isValidUser && getUserPreference(),
          isValidUser && getLobMetaMaster(),
          isValidUser && getLobMeta()
        ]);

        setUserPreference(response[4], UserPreferenceMasterData);
        const { filter, layout, status, platforms, tableModeColumns } =
          UserPreference;

        if (
          Config.IS_GTM_ENABLED &&
          Config.IS_GTM_ENABLED === 'true' &&
          Config.GTM_AUTH &&
          Config.GTM_ID &&
          Config.GTM_PREVIEW
        ) {
          gaInitializeGTM(Config.GTM_AUTH, Config.GTM_ID, Config.GTM_PREVIEW);
        }

        const selectedPlatforms = platforms.join(',');

        const tableColumns = tableModeColumns || tableModeColumnsMasterData;
        // removing columnOrder from tableModeColumns to match with the modal
        const formattedTableColumns = toJS(tableColumns).map(
          ({ columnOrder, ...rest }) => rest
        );

        // update filters, view mode, table columns according to user preference
        setSortCriteria(filter);
        toggleViewMode(layout);
        setProjectPublishStatus(status);
        setProjectPublishPlatform(selectedPlatforms);
        updateColumnVisibilityByUserPreference(selectedPlatforms, status);
        updateColumnOrder(formattedTableColumns);
      } else {
        disableLoader();
      }
    };

    fetchInitialData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // getting special permissions along with users list
    if (userId) {
      getPermissions();
    }
  }, [userId, getPermissions]);

  // fetching partner Health
  useLayoutEffect(() => {
    const checkHealth = async () => {
      const { isServiceDown: status, info } = await checkPartnerHealth();
      setIsServiceDown({ status, info });
    };
    if (userId) {
      checkHealth();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  // if partner health is down redirect to Service Down page
  if (isServiceDown.status) {
    return (
      <Router>
        <div className="container">
          <ServiceDown info={isServiceDown.info} showHeader />;
        </div>
      </Router>
    );
  }
  return (
    <Router>
      <PageTourWrapper />
      <div className="container">
        <Suspense fallback={<AppLoader />}>
          {/* used for global error/info message display in a popup(snackbar) */}
          <AlertMessage />
          <MessageDialog />
          <Loader />
          <BuildInfo />
          {userId && RELEASE_NUMBER && <NewReleaseDialog />}
          <NotificationDrawer />
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  {userId && UserPreference.layout && <HomePage />}
                </ProtectedRoute>
              }
            />
            <Route
              path="/project/version/:id/entity/:entityUrn?"
              element={
                <ProtectedRoute>
                  <ProjectDetailPage project={ProjectDetail} />
                </ProtectedRoute>
              }
            />
            {/* Redirection for preflight component for specific project */}
            <Route
              path="/preflight/projectId/:id/entityId/:entityUrn?"
              element={
                <ProtectedRoute>
                  {userId && <PreflightCheckPage />}
                </ProtectedRoute>
              }
            />
            {/* Redirection for Distribution page for specific project dUrn */}
            <Route
              path="/distribution/:id/distribute"
              element={
                <ProtectedRoute>
                  {userId && <DistributionPage />}
                </ProtectedRoute>
              }
            />
            <Route
              path="/alfrescoViewer/projectId/:id/entityId/:entityUrn?"
              element={
                <ProtectedRoute>{userId && <AlfrescoViewer />}</ProtectedRoute>
              }
            />
            <Route
              path="/import/projectId/:id/entityId/:entityUrn?"
              element={
                <ProtectedRoute>{userId && <ImportAssets />}</ProtectedRoute>
              }
            />
            <Route
              path="/paceAI/overview/:id"
              element={
                <ProtectedRoute>{userId && <PaceAIOverview />}</ProtectedRoute>
              }
            />
            <Route
              path="/lessonbuilder/projectId/:id/entityId/:entityUrn"
              exact
              element={
                <ProtectedRoute>{userId && <LessonBuilder />}</ProtectedRoute>
              }
            />
            <Route path="/forbidden" element={<ForbiddenPage />} />
            <Route path="*" element={<>404 Page Not found</>} />
          </Routes>
        </Suspense>
      </div>
    </Router>
  );
};

App.propTypes = {
  store: PropTypes.shape({
    FileUrls: PropTypes.shape({
      loadFileUrls: PropTypes.func.isRequired
    }),
    UserInfo: PropTypes.shape({
      getUserInfo: PropTypes.func.isRequired,
      userId: PropTypes.string.isRequired
    }),
    Config: PropTypes.shape({
      getConfig: PropTypes.func.isRequired,
      GOOGLE_ANALYTICS_TRACKING_ID: PropTypes.string.isRequired,
      RELEASE_NUMBER: PropTypes.string.isRequired,
      GTM_AUTH: PropTypes.string.isRequired,
      GTM_ID: PropTypes.string.isRequired,
      GTM_PREVIEW: PropTypes.string.isRequired,
      IS_GTM_ENABLED: PropTypes.string.isRequired
    }),
    Filters: PropTypes.shape({
      updateLOB: PropTypes.func.isRequired,
      setSortCriteria: PropTypes.func.isRequired,
      toggleViewMode: PropTypes.func.isRequired,
      setProjectPublishStatus: PropTypes.func.isRequired,
      setProjectPublishPlatform: PropTypes.func.isRequired,
      setShowNewReleaseContent: PropTypes.func.isRequired
    }),
    PublishPlatformOptions: PropTypes.shape({
      updateColumnVisibilityByUserPreference: PropTypes.func.isRequired
    }),
    SpecialPermissions: PropTypes.shape({
      getPermissions: PropTypes.func.isRequired
    }),
    FeatureManager: PropTypes.shape({
      getFeatureList: PropTypes.func.isRequired
    }),
    ProjectDetail: PropTypes.node.isRequired,
    UserPreference: PropTypes.shape({
      setUserPreference: PropTypes.func.isRequired,
      filter: PropTypes.string,
      layout: PropTypes.string,
      status: PropTypes.string,
      platforms: PropTypes.arrayOf(PropTypes.string),
      tableModeColumns: PropTypes.arrayOf(
        PropTypes.shape({
          draggable: PropTypes.bool,
          id: PropTypes.string,
          label: PropTypes.string,
          mandatory: PropTypes.bool,
          sortOn: PropTypes.string,
          visible: PropTypes.bool
        })
      )
    }),
    UserPreferenceMasterData: PropTypes.shape({
      getMasterData: PropTypes.func.isRequired,
      tableModeColumns: PropTypes.arrayOf(
        PropTypes.shape({
          draggable: PropTypes.bool,
          id: PropTypes.string,
          label: PropTypes.string,
          mandatory: PropTypes.bool,
          sortOn: PropTypes.string,
          visible: PropTypes.bool
        })
      )
    }),
    TableModeSettings: PropTypes.shape({
      updateColumnOrder: PropTypes.func.isRequired
    }),
    LobMetaMaster: PropTypes.shape({
      getLobMetaMaster: PropTypes.func.isRequired
    }),
    LobMeta: PropTypes.shape({
      getLobMeta: PropTypes.func.isRequired
    }),
    Loader: PropTypes.shape({
      disableLoader: PropTypes.func
    })
  })
};

export default withAuth(inject('store')(observer(App)));
